<template>
  <div class="about">
    <div>
      <img src="@/assets/images/bgimg2.png" alt="" class="bgimg" />
      <div class="container">
        <div class="main">
          <div style="padding: 0px 10px">
            <div class="font">{{ d("message.register") }}</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="phone">
                <el-input
                  size="large"
                  type="text"
                  :placeholder="d('message.phone')"
                  prefix-icon="el-icon-mobile-phone"
                  v-model="ruleForm.phone"
                ></el-input>
              </el-form-item>
              <el-row :gutter="16">
                <el-col class="gutter-row" :span="16">
                  <el-form-item prop="code">
                    <el-input
                      size="large"
                      type="text"
                      :placeholder="d('message.code')"
                      prefix-icon="el-icon-message"
                      v-model="ruleForm.code"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="gutter-row" :span="8">
                  <el-button
                    class="getCaptcha"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getCaptcha"
                    >{{
                      (!state.smsSendBtn && d("message.getCode")) ||
                      state.time + " s"
                    }}</el-button
                  >
                </el-col>
              </el-row>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  :placeholder="d('message.pwd')"
                  v-model="ruleForm.password"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item prop="checkPass">
                <el-input
                  type="password"
                  :placeholder="d('message.surePwd')"
                  v-model="ruleForm.checkPass"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="medium"
                  type="primary"
                  htmlType="submit"
                  class="register-button"
                  @click="handleSubmit"
                  >{{ d("message.register") }}</el-button
                >
                <div class="register" @click="toLogin">
                  {{ d("message.text") }}
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_top">
        <div>官网</div>
        <div>社区</div>
        <div>专业版</div>
      </div>
      <div style="margin-top: 20px">Copyright @ 图灵信科科技有限公司</div>
    </div>
  </div>
</template>
<script>
import { getRegisterCode, register } from "../api/login";
export default {
  name: "about",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {},
      state: {
        time: 60,
        loginType: 0,
        smsSendBtn: false,
      },
      rules: {
        phone: [
          {
            required: true,
            pattern: /^1[34578]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          {
            required: true,
            message: "请输入密码",
            validator: validatePass,
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            required: true,
            message: "请输入密码",
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
      appId: "",
      d: this.$t,
      arrObj: "",
    };
  },
  created() {
    let url = window.location.href;
    let arrObj = url.split("?");
    this.arrObj = arrObj[1];
    if (arrObj.length > 1) {
      let arrPara = arrObj[1]
        .split("&")
        .map((query) => query.split("="))
        .reduce((key, val) => ((key[val[0]] = val[1] || ""), key), {});
      //console.log("参数", arrPara);
      this.appId = arrPara.appId;
    }
  },
  methods: {
    handleSubmit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.ruleForm,
            appId: this.appId,
          };
          const res = await register(data);
          if (res.code == 200) {
            this.$router.push("/?" + this.arrObj);
          }else if (res.code == 456) {
            this.$router.push("/login?" + this.arrObj);
          }
        }
      });
    },
    getCaptcha() {
      this.$refs["ruleForm"].validateField("phone", async (valid) => {
        if (!valid) {
          this.state.smsSendBtn = true;
          await getRegisterCode(this.ruleForm.phone);
          const interval = window.setInterval(() => {
            if (this.state.time-- <= 0) {
              this.state.time = 60;
              this.state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
        }
      });
    },
    toLogin() {
      this.$router.push("/login?" + this.arrObj);
    },
  },
};
</script>
<style>
/* 移动端 */
@media screen and (max-width: 720px) {
  .about {
    min-height: 100vh;
    background: #f0f2f5;
  }
   .container {
    width: 100%;
    min-height: 100vh;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 260px;
    width: 100%;
    min-height: 100vh;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.9);
  }
  .font {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 0.4rem;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .register-button {
    width: 50%;
  }

  .register {
    float: right;
    color: #1890ff;
    font-size: 14px;
  }
  .el-button {
    padding: 0;
  }
  .bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666666;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    bottom: 0;
    z-index: 1000;
  }
  .bottom_top {
    display: flex;
    width: 200px;
    justify-content: space-around;
  }
}
/* pc端 */
@media screen and (min-width: 720px) {
  .about {
    min-height: 100vh;
    background: #f0f2f5;
    position: relative;
  }
  .container {
    width: 100%;
    min-height: 100vh;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 260px;
    width: 368px;
    min-height: 100vh;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.6);
  }
  .font {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 0.4rem;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .register-button {
    width: 50%;
  }

  .register {
    float: right;
    color: #1890ff;
    font-size: 14px;
  }
  .el-button {
    padding: 0;
  }
  .bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666666;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    bottom: 0;
    z-index: 1000;
  }
  .bottom_top {
    display: flex;
    width: 200px;
    justify-content: space-around;
  }
}
</style>